import { createMediaQuery } from "@solid-primitives/media";
import { createAsync, query } from "@solidjs/router";
import type { EmblaCarouselType } from "embla-carousel";
import Fade from "embla-carousel-fade";
import createEmblaCarousel from "embla-carousel-solid";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import {
  ErrorBoundary,
  For,
  Index,
  type Setter,
  Show,
  createSignal,
  onCleanup,
  onMount,
} from "solid-js";
import { isServer } from "solid-js/web";
import { Transition } from "solid-transition-group";

import { gql } from "~/__gql-generated__";
import styles from "~/components/FanGallery.module.scss";
import FooterButtonGroup from "~/components/FooterButtonGroup";
import rootStyles from "~/components/Root.module.scss";
import SmartA from "~/components/SmartA";
import { clientLazy } from "~/utils/clientLazy";
import {
  generateRetinaThumbs,
  generateWidthThumbs,
  optimize,
} from "~/utils/generateThumbs";
import { client } from "~/utils/graphql";

const FanGalleryFullscreen = clientLazy(
  () => import("~/components/FanGalleryFullscreen"),
);

const FAN_GALLERY = gql(`
  query FanGallery($limit: Int!, $offset: Int!) {
    fanGalleryItems(
      options: { sort: { createdAt: DESC }, limit: $limit, offset: $offset }
    ) {
      id
      title
      description
      author
      authorSocial
      createdAt
      image {
        url
      }
    }
    fanGalleryItemsAggregate {
      count
    }
  }
`);

export const innerGetFanGallery = async (limit = 5, page = 0) => {
  "use server";

  const { data } = await client.query({
    query: FAN_GALLERY,
    variables: { limit, offset: page * limit },
  });

  return {
    items: data.fanGalleryItems.map((item) => ({
      ...item,
      imageThumbs: generateRetinaThumbs(item.image.url, "640x"),
      image: optimize(item.image.url),
    })),
    count: data.fanGalleryItemsAggregate.count,
    heroImageSet: generateWidthThumbs(
      `${import.meta.env.VITE_SITE_HOST}/img/desenho-ouvinte.png`,
      16 / 9,
    ),
  };
};
const getFanGallery = query(innerGetFanGallery, "fanGallery");

export type FanGalleryItems = Awaited<
  ReturnType<typeof innerGetFanGallery>
>["items"];

export default function FanGallery() {
  const data = createAsync(() => getFanGallery(), {
    initialValue: { items: [], count: -1, heroImageSet: "" },
  });

  const reducedMotion = createMediaQuery(
    "(prefers-reduced-motion: reduce)",
    false,
  );

  const carouselPlugins = () =>
    [Fade({ active: reducedMotion() }), WheelGesturesPlugin()] as any;

  let [carouselRef, carouselApi] = [() => {}, () => {}] as ReturnType<
    typeof createEmblaCarousel
  >;
  if (!isServer) {
    [carouselRef, carouselApi] = createEmblaCarousel(
      () => ({
        direction: isServer
          ? "ltr"
          : (globalThis.document.documentElement.dir as "ltr" | "rtl"),
      }),
      carouselPlugins,
    );
  }

  const [scrollSnaps, setScrollSnaps] = createSignal<number[]>([]);
  const [selectedIndex, setSelectedIndex] = createSignal(0);

  const selectedItem = () => data().items[selectedIndex()];

  const onInit = (api: EmblaCarouselType) => {
    setScrollSnaps(api.scrollSnapList());
  };

  const onSelect = (api: EmblaCarouselType) => {
    setSelectedIndex(api.selectedScrollSnap());
  };

  onMount(() => {
    const api = carouselApi();

    if (!api) {
      return;
    }

    onInit(api);
    onSelect(api);
    api.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);

    onCleanup(() => {
      api.off("reInit", onInit).off("reInit", onSelect).off("select", onSelect);
    });
  });

  const scrollPrevious = () => {
    carouselApi()?.scrollPrev();
  };

  const scrollNext = () => {
    carouselApi()?.scrollNext();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowLeft") {
      setPressedDirection(-1);
      scrollPrevious();
    }

    if (event.key === "ArrowRight") {
      setPressedDirection(1);
      scrollNext();
    }
  };

  const handleKeyUp = () => {
    setPressedDirection(0);
  };

  const [pressedDirection, setPressedDirection] = createSignal(0);

  const [wrapperRef, setWrapperRef] = createSignal<HTMLDivElement>();

  onMount(() => {
    const carousel = wrapperRef();

    if (!carousel) {
      return;
    }

    carousel.tabIndex = 0;
    carousel.querySelector(`.${styles.items}`)?.removeAttribute("tabindex");
  });

  const [fullscreenMode, setFullscreenMode] = createSignal(false);

  const canScrollPrevious = () => selectedIndex() > 0;
  const canScrollNext = () => selectedIndex() < data().items.length - 1;

  return (
    <>
      <section
        classList={{ [styles.wrapper]: true, [rootStyles.section]: true }}
        aria-labelledby="gallery-label"
        aria-roledescription="Carrossel"
      >
        <h2 id="gallery-label">
          Galeria do Ouvinte
          <span>
            <IconTablerPhoto />
          </span>
        </h2>
        <div
          ref={setWrapperRef}
          class={styles["gallery-wrapper"]}
          tabIndex={isServer ? undefined : 0}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
        >
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
          <div
            ref={carouselRef}
            class={styles.gallery}
            onClick={() => {
              setFullscreenMode(true);
            }}
          >
            <div class={styles.items} tabIndex={isServer ? 0 : undefined}>
              <For each={data().items}>
                {(item, index) => (
                  <div
                    class={styles.item}
                    // biome-ignore lint/a11y/useSemanticElements: <explanation>
                    role="group"
                    aria-roledescription="Slide"
                    aria-labelledby={`gallery-slide-${index().toString()}`}
                    aria-hidden={!isServer && selectedIndex() !== index()}
                  >
                    <h3
                      id={`gallery-slide-${index().toString()}`}
                      class={rootStyles["sr-only"]}
                    >
                      <cite>{item.title}</cite>, por {item.author}
                    </h3>
                    <img
                      loading="lazy"
                      src={item.image}
                      srcset={item.imageThumbs}
                      alt={item.description}
                    />
                  </div>
                )}
              </For>
            </div>
          </div>
          <Show when={!isServer}>
            <div aria-hidden="true" class={styles.meta}>
              <Transition mode="outin" name="meta-fade">
                <Show keyed when={selectedItem()}>
                  {(item) => (
                    <div class={styles["meta-inner"]}>
                      <cite class={styles["item-title"]}>{item.title}</cite>
                      <span>por {item.author}</span>
                    </div>
                  )}
                </Show>
              </Transition>
            </div>
          </Show>
          <Show when={!isServer}>
            <FooterButtonGroup>
              <button
                type="button"
                tabIndex={-1}
                classList={{
                  [styles.previous]: true,
                  pressed: pressedDirection() === -1,
                }}
                disabled={!canScrollPrevious()}
                onClick={scrollPrevious}
              >
                <IconTablerChevronLeft />
                <span class={rootStyles["sr-only"]}>Anterior</span>
              </button>
              <button
                tabIndex={-1}
                type="button"
                classList={{
                  [styles.next]: true,
                  pressed: pressedDirection() === 1,
                }}
                disabled={!canScrollNext()}
                onClick={scrollNext}
              >
                <IconTablerChevronRight />
                <span class={rootStyles["sr-only"]}>Próximo</span>
              </button>
            </FooterButtonGroup>
          </Show>
        </div>
        <p>
          <Show when={!isServer} fallback={"\u00A0"}>
            <span
              // biome-ignore lint/a11y/useSemanticElements: <explanation>
              role="group"
              class={styles.dots}
              aria-labelledby="gallery-items"
            >
              <span id="gallery-items" class={rootStyles["sr-only"]}>
                Itens da galeria
              </span>
              <Index each={scrollSnaps()}>
                {(_, index) => (
                  <button
                    type="button"
                    aria-disabled={selectedIndex() === index}
                    onClick={() => {
                      carouselApi()?.scrollTo(index);
                    }}
                  >
                    <span class={rootStyles["sr-only"]}>
                      Mostrar desenho {index + 1} de {scrollSnaps().length}:{" "}
                      {data().items[index].title}
                    </span>
                  </button>
                )}
              </Index>
            </span>
          </Show>
          <SmartA href="/desenho-ouvinte">Ver Mais</SmartA>
        </p>
      </section>
      <Show when={!isServer}>
        <FanGalleryFullscreen
          open={fullscreenMode()}
          data={data().items}
          active={selectedIndex()}
          onChangeActive={(index) => {
            carouselApi()?.scrollTo(index);
          }}
          onClose={() => {
            setFullscreenMode(false);
          }}
        />
      </Show>
    </>
  );
}
